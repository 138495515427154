import { AnyAction } from '@reduxjs/toolkit'
import { VenuesApi } from 'middleware'
import {
  Venues,
  VenueSensorsMap,
  VenueSensorStatusMap,
  VenuesHardwareStats,
  VenuesMap,
  VenuesNAOConfig,
  VenueTrackablesMap,
  VenueUsers,
} from 'models'
import { ReadonlyState } from 'models/baseModels'
import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import {
  VenueHardwareStatsResponse,
  venueHardwareStatsSlice,
  VenueNAOConfigResponse,
  venueNaoConfigSlice,
  VenueSensorsOfflineOnlineStatusResponse,
  venueSensorsOfflineOnlineStatusSlice,
  VenueSensorsResponse,
  venueSensorsSlice,
  venueSlice,
  venuesSlice,
  VenueTrackablesResponse,
  venueTrackablesSlice,
  VenueUsersResponse,
  venueUsersSlice,
} from '../reducers/venues'

type VenuesEpic = Epic<
  AnyAction,
  AnyAction,
  ReadonlyState<Venues>,
  { venuesApi: VenuesApi }
>

export const getallVenuesEpic: VenuesEpic = (
  action$,
  _state,
  { venuesApi }
) => {
  const actions = venuesSlice.actions
  return action$.pipe(
    filter(actions.getVenuesRequest.match),
    mergeMap((action) => {
      return from(venuesApi.getAllVenues(action.payload)).pipe(
        map((Venues) => {
          return actions.getVenuesSuccess(Venues)
        }),
        catchError((error) => {
          console.log('Error retrieving venues', error)
          return of(actions.getVenuesFailure(error))
        })
      )
    })
  )
}

type VenueEpic = Epic<
  AnyAction,
  AnyAction,
  ReadonlyState<VenuesMap>,
  { venuesApi: VenuesApi }
>

export const getVenueEpic: VenueEpic = (action$, _state, { venuesApi }) => {
  const actions = venueSlice.actions
  return action$.pipe(
    filter(actions.getVenueRequest.match),
    mergeMap((action) => {
      return from(venuesApi.getVenue(action.payload)).pipe(
        map((Venue) => {
          return actions.getVenueSuccess(Venue)
        }),
        catchError((error) => {
          console.log('Error retrieving venue', error)
          return of(actions.getVenueFailure(error))
        })
      )
    })
  )
}

type VenueTrackablesEpic = Epic<
  AnyAction,
  AnyAction,
  ReadonlyState<VenueTrackablesMap>,
  { venuesApi: VenuesApi }
>

export const getVenueTrackablesEpic: VenueTrackablesEpic = (
  action$,
  _state,
  { venuesApi }
) => {
  const actions = venueTrackablesSlice.actions
  return action$.pipe(
    filter(actions.getVenueTrackablesRequest.match),
    mergeMap((action) => {
      return from(venuesApi.getVenueTrackables(action.payload)).pipe(
        map((trackables) => {
          const payload: VenueTrackablesResponse = {
            venueId: action.payload,
            trackables,
          }
          return actions.getVenueTrackablesSuccess(payload)
        }),
        catchError((error) => {
          console.log('Error retrieving hardware stats for venue', error)
          return of(actions.getVenueTrackablesFailure(error))
        })
      )
    })
  )
}

type VenueSensorsEpic = Epic<
  AnyAction,
  AnyAction,
  ReadonlyState<VenueSensorsMap>,
  { venuesApi: VenuesApi }
>

export const getVenueSensorsEpic: VenueSensorsEpic = (
  action$,
  _state,
  { venuesApi }
) => {
  const actions = venueSensorsSlice.actions
  return action$.pipe(
    filter(actions.getVenueSensorsRequest.match),
    mergeMap((action) => {
      return from(venuesApi.getVenueSensors(action.payload)).pipe(
        map((sensors) => {
          const payload: VenueSensorsResponse = {
            venueId: action.payload,
            sensors,
          }
          return actions.getVenueSensorsSuccess(payload)
        }),
        catchError((error) => {
          console.log('Error retrieving sensors for venue', error)
          return of(actions.getVenueSensorsFailure(error))
        })
      )
    })
  )
}

type VenueSensorsOfflineOnlineStatusEpic = Epic<
  AnyAction,
  AnyAction,
  ReadonlyState<VenueSensorStatusMap>,
  { venuesApi: VenuesApi }
>

export const getVenueSensorsOfflineOnlineStatusEpic: VenueSensorsOfflineOnlineStatusEpic =
  (action$, _state, { venuesApi }) => {
    const actions = venueSensorsOfflineOnlineStatusSlice.actions
    return action$.pipe(
      filter(actions.getVenueSensorsOfflineOnlineStatusRequest.match),
      mergeMap((action) => {
        return from(
          venuesApi.getVenueSensorsOfflineOnlineStatus(action.payload)
        ).pipe(
          map((sensorsStatus) => {
            const payload: VenueSensorsOfflineOnlineStatusResponse = {
              venueId: action.payload,
              sensorsStatus,
            }
            return actions.getVenueSensorsOfflineOnlineStatusSuccess(payload)
          }),
          catchError((error) => {
            console.log(
              'Error retrieving sensor offline/online status for venue',
              error
            )
            return of(actions.getVenueSensorsOfflineOnlineStatusFailure(error))
          })
        )
      })
    )
  }

type VenueHardwareStatsEpic = Epic<
  AnyAction,
  AnyAction,
  ReadonlyState<VenuesHardwareStats>,
  { venuesApi: VenuesApi }
>

export const getVenueHardwareStatsEpic: VenueHardwareStatsEpic = (
  action$,
  _state,
  { venuesApi }
) => {
  const actions = venueHardwareStatsSlice.actions
  return action$.pipe(
    filter(actions.getVenueHardwareStatsRequest.match),
    mergeMap((action) => {
      return from(venuesApi.getVenueHardwareStats(action.payload)).pipe(
        map((hardwareStats) => {
          const payload: VenueHardwareStatsResponse = {
            venueId: action.payload,
            hardwareStats,
          }
          return actions.getVenueHardwareStatsSuccess(payload)
        }),
        catchError((error) => {
          console.log('Error retrieving hardware stats for venue', error)
          return of(actions.getVenueHardwareStatsFailure(error))
        })
      )
    })
  )
}

type VenueNAOConfigEpic = Epic<
  AnyAction,
  AnyAction,
  ReadonlyState<VenuesNAOConfig>,
  { venuesApi: VenuesApi }
>

export const getVenueNaoConfigEpic: VenueNAOConfigEpic = (
  action$,
  _state,
  { venuesApi }
) => {
  const actions = venueNaoConfigSlice.actions
  return action$.pipe(
    filter(actions.getVenueNaoConfigRequest.match),
    mergeMap((action) => {
      return from(venuesApi.getVenueNAOConfig(action.payload)).pipe(
        map((naoConfig) => {
          const payload: VenueNAOConfigResponse = {
            venueId: action.payload,
            naoConfig,
          }
          return actions.getVenueNaoConfigSuccess(payload)
        }),
        catchError((error) => {
          console.log('Error retrieving nao config for venue', error)
          return of(actions.getVenueNaoConfigFailure(error))
        })
      )
    })
  )
}

type VenueUsersEpic = Epic<
  AnyAction,
  AnyAction,
  ReadonlyState<VenueUsers>,
  { venuesApi: VenuesApi }
>

export const getVenueUsersEpic: VenueUsersEpic = (
  action$,
  _state,
  { venuesApi }
) => {
  const actions = venueUsersSlice.actions
  return action$.pipe(
    filter(actions.getVenueUsersRequest.match),
    mergeMap((action) => {
      return from(venuesApi.getVenueUsers(action.payload)).pipe(
        map((venuUsers) => {
          const payload: VenueUsersResponse = {
            venueId: action.payload,
            users: venuUsers,
          }
          return actions.getVenueUsersSuccess(payload)
        }),
        catchError((error) => {
          console.log('Error retrieving nao config for venue', error)
          return of(actions.getVenueUsersFailure(error))
        })
      )
    })
  )
}
