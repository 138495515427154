import { sanitizeLogString } from '@midmarkrtls/common/helpers'
import { HttpStatusCode } from '@midmarkrtls/common/services/http/enums'
import {
  HttpErrorResponse,
  HttpResponse,
} from '@midmarkrtls/common/services/http/response'
import { Authentication } from '../../authentication/AuthenticationManager'

export const HttpErrorMessages = {
  UnavailableResourceError:
    'A request was made to a resource that is not available',
  UnauthorizedError: 'An anonymous request was made to an authorized resource',
  ForbiddenError: 'A request was made to a resource that is forbidden',
}

export function getDefaultStatusMessage(
  status: HttpStatusCode | number
): string {
  let message = HttpErrorMessages.UnavailableResourceError
  switch (status) {
    case 401:
      message = HttpErrorMessages.UnauthorizedError
      break
    case 403:
      message = HttpErrorMessages.ForbiddenError
      break
  }

  return message
}

export class HttpError extends Error {
  showError = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public response: HttpResponse<any>, message: string) {
    super(message)
  }
}

export class UnauthorizedRequestError extends HttpError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public response: HttpResponse<any>, message?: string) {
    super(response, message || getDefaultStatusMessage(response.status))
  }
}

export class HttpErrorHandler {
  handle(errorResponse: HttpErrorResponse): void {
    switch (errorResponse.status) {
      case HttpStatusCode.Unauthorized:
        this.handleUnauthorized(errorResponse)
        break
      case HttpStatusCode.Forbidden:
        this.handleForbidden(errorResponse)
        break
      default:
        this.handleUnknownError(errorResponse)
    }
  }

  private handleUnauthorized(errorResponse: HttpErrorResponse): void {
    if (Authentication != undefined) {
      Authentication.logout()
    }

    console.error(
      sanitizeLogString(
        `Http request made by anonymous user to authenticated endpoint ${errorResponse.url}`
      )
    )
  }

  private handleForbidden(errorResponse: HttpErrorResponse): void {
    const account = Authentication.account

    console.error(
      sanitizeLogString(
        `Http request made by ${account.name} to authenticated endpoint ${errorResponse.url}`
      )
    )
  }

  private handleUnknownError(errorResponse: HttpErrorResponse): void {
    console.error(
      sanitizeLogString(
        `Http request to endpoint ${errorResponse.url} resulted in an error`
      )
    )
  }
}
