import { User, UserDetails } from '../models/users'
import { BaseService } from './baseService'

export class UsersApi extends BaseService {
  constructor() {
    super({ entityType: 'users', version: 1 })
  }
  route = 'Users'

  async getAllUsers(): Promise<User[]> {
    return await this.get<User[]>(this.route)
  }

  async getUserDetails(objectId: string): Promise<UserDetails> {
    return await this.get<UserDetails>(`users/objectId/${objectId}`)
  }
}
