import { createStyles, makeStyles } from '@material-ui/core'
import { getAssociatedRoute } from 'helpers/routing'
import { ReactNode } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'reducers'
import pageRoutes, { RouteKeys } from 'routes'
import Header from '../components/Common/Header'

import SideNav from 'components/Common/SideNav/SideNav'
import { authAllowedPageGroups } from 'modules'
import { clearStatus } from 'reducers/status'
import Notifications from '../components/Common/Notifications/Notifications'

function isIOS(): boolean {
  return (
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' &&
      navigator.maxTouchPoints > 1 &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      !(window as any).MSStream)
  )
}

function height(): string {
  let height = '100vh'
  if (isIOS()) {
    height = window.innerHeight + 'px'
  }
  return height
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: height(),
      width: '100vw',
      overflow: 'hidden',
    },
    rows: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      overflowY: 'hidden',
    },
    columns: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    content: {
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      minHeight: 0,
      position: 'relative',
    },
  })
)

interface Props {
  status: readonly string[]
  clearStatus: () => void
  children: ReactNode
}

const RootLayout = (props: Props): JSX.Element => {
  const { status, clearStatus, children } = props

  const classes = useStyles()
  const history = useHistory()

  const handleLogoClick = (): void => {
    history.push(
      getAssociatedRoute(RouteKeys.Dashboard, pageRoutes)?.path ?? ''
    )
  }

  return (
    <div className={classes.root}>
      <Header handleLogoClick={handleLogoClick} />
      <div className={classes.rows}>
        {<SideNav pageGroups={authAllowedPageGroups} />}
        <div className={classes.columns}>
          <div className={classes.rows}>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
      </div>
      <Notifications status={status} clearStatus={clearStatus} />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    status: state.status,
  }
}

export default connect(mapStateToProps, {
  clearStatus: () => clearStatus(),
})(RootLayout)
