import React, { useCallback, useEffect, useState } from 'react'

export type WindowContextProps = {
  windowHeight: number
  windowWidth: number
}

export const WindowContext = React.createContext<WindowContextProps>({
  windowHeight: 0,
  windowWidth: 0,
})

export const WindowContextProvider: React.FC = ({ children }) => {
  const getViewHeight = useCallback(() => {
    return Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    )
  }, [])

  const getViewWidth = useCallback(() => {
    return Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )
  }, [])

  const [windowHeight, setViewHeight] = useState<number>(getViewHeight())
  const [windowWidth, setViewWidth] = useState<number>(getViewWidth())

  useEffect(() => {
    const handleResize = () => {
      setViewHeight(getViewHeight())
      setViewWidth(getViewWidth())
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [getViewHeight, getViewWidth()])

  return (
    <WindowContext.Provider value={{ windowHeight, windowWidth }}>
      {children}
    </WindowContext.Provider>
  )
}
