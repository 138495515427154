import { permittedRoute } from '@midmarkrtls/common/helpers'
import { useAuth } from '@midmarkrtls/common/hooks'
import { PageRoute } from '@midmarkrtls/common/interfaces/modules'
import { PageWrapper } from '@midmarkrtls/common/pages'
import React, { ReactNode, useMemo } from 'react'
import { Redirect, Route } from 'react-router-dom'

interface Props {
  routes: PageRoute[]
}

export const withRedirectTo = (path: string) => (): ReactNode =>
  (<Redirect to={path} />) as ReactNode

const Routes: React.FC<Props> = (props: Props) => {
  const { routes } = props
  const { permissions } = useAuth()

  const userRoutes = useMemo(() => {
    return routes.reduce((acc: JSX.Element[], route) => {
      const Component = route.redirectTo
        ? withRedirectTo(route.redirectTo)
        : route.pageComponent
      const isPermitted =
        route.permissions.length === 0 ||
        permittedRoute(route.permissions, permissions ?? [])
      if (isPermitted) {
        acc.push(
          <Route key={route.key} exact={route.exact} path={route.path}>
            <PageWrapper>
              <Component />
            </PageWrapper>
          </Route>
        )
      }
      return acc
    }, [])
  }, [routes, permissions])

  return <>{userRoutes}</>
}

export default Routes
