import { NAOCloudSecrets } from 'models/auth'
import { User } from 'models/users'
import { Sensor, SensorOfflineOnlineStatus, Trackable } from '../models'
import { AggregateSensorTrackableDetection } from '../models/detections'
import { Venue, VenueHardwareStats } from '../models/venues'
import { BaseService } from './baseService'

export class VenuesApi extends BaseService {
  constructor() {
    super({ entityType: 'venues', version: 1 })
  }
  route = 'Venues'

  async getAllVenues(includeDeactivated: boolean): Promise<Venue[]> {
    return await this.get<Venue[]>(
      `${this.route}/?includeDeactivated=${includeDeactivated}`
    )
  }

  async getVenue(venueId: string): Promise<Venue> {
    return await this.get<Venue>(`${this.route}/${venueId}`)
  }

  async getVenueTrackables(venueId: string): Promise<Trackable[]> {
    return await this.get<Trackable[]>(`${this.route}/${venueId}/Trackables`)
  }

  async getVenueSensors(venueId: string): Promise<Sensor[]> {
    return await this.get<Sensor[]>(`${this.route}/${venueId}/Sensors`)
  }

  async getVenueSensorsOfflineOnlineStatus(
    venueId: string,
    minReportedDateTime?: string,
    offlineThresholdTimespan?: number
  ): Promise<SensorOfflineOnlineStatus[]> {
    const optionalParam1 = minReportedDateTime
      ? `minReportDateTime=${minReportedDateTime}`
      : ''
    const optionalParam2 = offlineThresholdTimespan
      ? `offlineThresholdTimespan=${offlineThresholdTimespan}`
      : ''
    if (optionalParam1 != '' && optionalParam2 != '') {
      optionalParam1 + '&'
    }
    return await this.get<SensorOfflineOnlineStatus[]>(
      `${this.route}/${venueId}/Sensors/Status?${optionalParam1}${optionalParam2}`
    )
  }

  async getVenueHardwareStats(venueId: string): Promise<VenueHardwareStats> {
    return await this.get<VenueHardwareStats>(
      `${this.route}/${venueId}/hardwareStats?useExternalSource=${true}`
    )
  }

  async getVenueAggregateSensorDetections(
    venueId: string,
    trackableMacAddress: string,
    startDatetime: string,
    endDatetime: string
  ): Promise<AggregateSensorTrackableDetection[]> {
    return await this.get<AggregateSensorTrackableDetection[]>(
      `${this.route}/${venueId}/Trackables/${trackableMacAddress}/AggregatedSensorDetections?startDate=${startDatetime}&endDate=${endDatetime}`
    )
  }

  async getVenueNAOConfig(venueId: string): Promise<NAOCloudSecrets> {
    return await this.get<NAOCloudSecrets>(`${this.route}/${venueId}/naoConfig`)
  }

  async getVenueUsers(venueId: string): Promise<User[]> {
    return await this.get<User[]>(`venues/${venueId}/users`)
  }
}
