import { HttpEvent } from '@midmarkrtls/common/services/http/events'
import { HttpRequest } from '@midmarkrtls/common/services/http/request'
import { HttpErrorResponse } from '@midmarkrtls/common/services/http/response'
import { from, Observable, throwError } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { HttpErrorHandler, HttpHandler } from '.'
import { Authentication } from '../../authentication/AuthenticationManager'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type HttpInterceptorCondition = (request: HttpRequest<any>) => boolean
export interface HttpInterceptor {
  appliesTo?: HttpInterceptorCondition
  intercept(
    request: HttpRequest<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
    next: HttpHandler
  ): Observable<HttpEvent<any>> // eslint-disable-line @typescript-eslint/no-explicit-any
}

export abstract class HttpRequestTransformer implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
    next: HttpHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    const transformedRequest = this.transform(request)

    return next.handle(transformedRequest)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract transform(request: HttpRequest<any>): HttpRequest<any>
}

export class HttpValidTokenInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
    next: HttpHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    const loginRequest = Authentication.authConfig.loginRequest
    const account = Authentication.account

    if (!account) {
      throw 'No user account found, unable to validate token'
    }

    const response = Authentication.clientApplication
      .acquireTokenSilent({
        ...loginRequest,
        account: account,
      })
      .then((resp) => {
        const headers: Record<string, string> = {
          Authorization: `Bearer ${resp.accessToken}`,
        }
        return request.clone({
          setHeaders: headers,
        })
      })
      .catch((err) => {
        Authentication.logout()
        return err
      })

    return from(response).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      switchMap((req: HttpRequest<any>) => {
        return next.handle(req)
      })
    )
  }
}

// export class AddTenantRequestTransformer extends HttpRequestTransformer {
//   constructor() {
//     super()
//   }

//   // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
//   appliesTo(request: HttpRequest<any>): boolean {
//     return Authentication.isLoggedIn()
//   }

//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   transform(request: HttpRequest<any>): HttpRequest<any> {
//     const headers: Record<string, string> = {}

//     const customerImpersonationId = Authentication.tenantImpersonationId

//     if (customerImpersonationId) {
//       headers[tenantHeader] = customerImpersonationId
//     }

//     return request.clone({
//       setHeaders: headers,
//     })
//   }
// }

export class HandleHttpErrorsInterceptor implements HttpInterceptor {
  private readonly errorHandler: HttpErrorHandler
  constructor() {
    this.errorHandler = new HttpErrorHandler()
  }

  intercept(
    request: HttpRequest<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
    next: HttpHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          const errorResponse = error as HttpErrorResponse
          this.errorHandler.handle(errorResponse)
        }

        return throwError(error)
      })
    )
  }
}

export const getInterceptors = (): HttpInterceptor[] => [
  new HttpValidTokenInterceptor(),
  // new AddTenantRequestTransformer(),
  new HandleHttpErrorsInterceptor(),
]
