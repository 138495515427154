import { createStyles, makeStyles } from '@material-ui/core'
import RootSideNavLink from '@midmarkrtls/common/components/Common/SideNav/RootSideNavLink'
import { PageGroup as PageGroupType } from '@midmarkrtls/common/interfaces/modules'
import { pathMatches } from 'helpers/routing'
import React, { Fragment, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { colors } from 'styles/MidmarkTheme'
import PageGroup from './PageGroup'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    row: {
      color: 'white',
      height: 56,
      '&:hover': {
        backgroundColor: colors.magentaHalf,
      },
    },
    pageGroup: {
      borderBottom: '1px solid ' + colors.background,
    },
    pageListItemText: {
      fontWeight: 'bold',
    },
    selectedRow: {
      backgroundColor: colors.magenta,
    },
    subSelectedRow: {
      backgroundColor: colors.darkMagenta,
    },
    iconCell: {
      color: 'white',
      minWidth: '56px',
      justifyContent: 'center',
    },
    opaqueIcon: {
      opacity: '0.54',
    },
    caretExpanded: {
      '&::after': {
        content: '""',
        border: '5px solid transparent',
        borderBottom: '5px solid white',
        marginRight: '25px',
        marginBottom: '5px',
        display: 'inline-block',
        opacity: '0.6',
      },
    },
    caretCollapsed: {
      '&::after': {
        content: '""',
        border: '5px solid transparent',
        borderTop: '5px solid white',
        marginRight: '25px',
        marginTop: '5px',
        display: 'inline-block',
        opacity: '0.6',
      },
    },
  })
)

interface Props {
  isOpen: boolean
  pageGroups: PageGroupType[]
}

const SideNavLinks: React.FC<Props> = (props: Props) => {
  const { isOpen, pageGroups } = props

  const classes = useStyles()
  const { pathname } = useLocation()

  //TODO: Add filtering by permission if desired
  // const permissionPageGroups = useMemo(
  //   () => filteredPageGroups(pageGroups, permissions),
  //   [pageGroups, permissions]
  // )

  const [expandedGroups, setExpandedGroups] = useState(() => {
    const expandedGroup = pageGroups.find((x) =>
      x.pages.find((page) =>
        page.associatedRoute
          ? pathMatches(page.associatedRoute.path, pathname)
          : null
      )
    )

    return expandedGroup ? [expandedGroup.key] : []
  })

  const handlePageSelect = (groupKey?: string) => {
    setExpandedGroups(groupKey ? [groupKey] : [])
  }

  const handlePageGroupClick = (groupKey: string, expanded: boolean) => {
    if (expanded) {
      setExpandedGroups((prevVal) => [...prevVal, groupKey])
    } else {
      setExpandedGroups((prevVal) => [
        ...prevVal.filter((key) => key !== groupKey),
      ])
    }
  }

  return (
    <div id='SideNavLinks' className={classes.root}>
      {pageGroups.map((pageGroup) => {
        return (
          <Fragment key={pageGroup.key}>
            {pageGroup.pages.length ? (
              <PageGroup
                name={pageGroup.name}
                icon={pageGroup.icon}
                pageGroupKey={pageGroup.key}
                pages={pageGroup.pages}
                classes={classes}
                isOpen={isOpen}
                isExpanded={!!expandedGroups.find((x) => x === pageGroup.key)}
                selectedPage={
                  pageGroup.pages.find((x) =>
                    x.associatedRoute
                      ? pathMatches(
                          x.associatedRoute.redirectTo ??
                            x.associatedRoute.path,
                          pathname
                        )
                        ? true
                        : x.associatedRoute.subroutes
                            .map((y) => {
                              return pathMatches(
                                x.associatedRoute?.path + y,
                                pathname
                              )
                            })
                            .some((x) => x === true)
                      : null
                  )?.key
                }
                handlePageSelect={handlePageSelect}
                handlePageGroupClick={handlePageGroupClick}
              />
            ) : !!pageGroup.associatedRoute ? (
              <RootSideNavLink
                to={`/${pageGroup.link}`}
                route={pageGroup.associatedRoute}
                name={pageGroup.name}
                icon={pageGroup.icon}
                isOpen={isOpen}
                classes={classes}
                handlePageSelect={() => handlePageSelect()}
              />
            ) : null}
          </Fragment>
        )
      })}
    </div>
  )
}

export default SideNavLinks
