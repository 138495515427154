import { FetchingStatus } from '@midmarkrtls/common/models'
import { mergeRecords } from '@midmarkrtls/common/utils'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  NAOCloudSecrets,
  Sensor,
  SensorOfflineOnlineStatus,
  Trackable,
  VenueSensorsMap,
  VenueSensorStatusMap,
  VenueTrackablesMap,
} from 'models'
import {
  getInitialReducerState,
  getInitialReducerStateForId,
} from 'models/baseModels'
import { User } from 'models/users'
import {
  Venue,
  VenueHardwareStats,
  Venues,
  VenuesHardwareStats,
  VenuesMap,
  VenuesNAOConfig,
  VenueUsers,
} from 'models/venues'
import { baseFetchFailure } from './baseReducers'

export interface VenueHardwareStatsResponse {
  venueId: string
  hardwareStats: VenueHardwareStats
}

export interface VenueTrackablesResponse {
  venueId: string
  trackables: Trackable[]
}

export interface VenueSensorsResponse {
  venueId: string
  sensors: Sensor[]
}

export interface VenueSensorsOfflineOnlineStatusResponse {
  venueId: string
  sensorsStatus: SensorOfflineOnlineStatus[]
}

export interface VenueNAOConfigResponse {
  venueId: string
  naoConfig: NAOCloudSecrets
}

export interface VenueUsersResponse {
  venueId: string
  users: User[]
}

export const venuesSlice = createSlice({
  name: 'venues',
  initialState: getInitialReducerState<Venues>(),
  reducers: {
    getVenuesSuccess: (state, action: PayloadAction<Venue[]>) => {
      state.status = FetchingStatus.Success
      state.lastRefreshTime = new Date()
      state.data = mergeRecords<Venues, Venue, 'id'>(
        state.data,
        action.payload,
        'id'
      )
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getVenuesRequest: (state, includeDeactivated: PayloadAction<boolean>) => {
      state.status = FetchingStatus.Request
    },
    getVenuesFailure: baseFetchFailure,
  },
})

export const venueSlice = createSlice({
  name: 'venue',
  initialState: getInitialReducerStateForId<VenuesMap>(),
  reducers: {
    getVenueSuccess: (state, action: PayloadAction<Venue>) => {
      state.status = FetchingStatus.Success

      if (action.payload.id) {
        state.lastRefreshTimes[action.payload.id] = new Date()
        state.data[action.payload.id] = action.payload
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getVenueRequest: (state, venueId: PayloadAction<string>) => {
      state.status = FetchingStatus.Request
    },
    getVenueFailure: baseFetchFailure,
  },
})

export const venueTrackablesSlice = createSlice({
  name: 'venueTrackables',
  initialState: getInitialReducerStateForId<VenueTrackablesMap>(),
  reducers: {
    getVenueTrackablesSuccess: (
      state,
      action: PayloadAction<VenueTrackablesResponse>
    ) => {
      state.status = FetchingStatus.Success

      if (action.payload.venueId) {
        state.lastRefreshTimes[action.payload.venueId] = new Date()
        state.data[action.payload.venueId] = action.payload.trackables
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getVenueTrackablesRequest: (state, venueId: PayloadAction<string>) => {
      state.status = FetchingStatus.Request
    },
    getVenueTrackablesFailure: baseFetchFailure,
  },
})

export const venueSensorsSlice = createSlice({
  name: 'venueSensors',
  initialState: getInitialReducerStateForId<VenueSensorsMap>(),
  reducers: {
    getVenueSensorsSuccess: (
      state,
      action: PayloadAction<VenueSensorsResponse>
    ) => {
      state.status = FetchingStatus.Success

      if (action.payload.venueId) {
        state.lastRefreshTimes[action.payload.venueId] = new Date()
        state.data[action.payload.venueId] = action.payload.sensors
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getVenueSensorsRequest: (state, venueId: PayloadAction<string>) => {
      state.status = FetchingStatus.Request
    },
    getVenueSensorsFailure: baseFetchFailure,
  },
})

export const venueSensorsOfflineOnlineStatusSlice = createSlice({
  name: 'venueSensorsOfflineOnlineStatus',
  initialState: getInitialReducerStateForId<VenueSensorStatusMap>(),
  reducers: {
    getVenueSensorsOfflineOnlineStatusSuccess: (
      state,
      action: PayloadAction<VenueSensorsOfflineOnlineStatusResponse>
    ) => {
      state.status = FetchingStatus.Success

      if (action.payload.venueId) {
        state.lastRefreshTimes[action.payload.venueId] = new Date()
        state.data[action.payload.venueId] = action.payload.sensorsStatus
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getVenueSensorsOfflineOnlineStatusRequest: (
      state,
      venueId: PayloadAction<string>
    ) => {
      state.status = FetchingStatus.Request
    },
    getVenueSensorsOfflineOnlineStatusFailure: baseFetchFailure,
  },
})

export const venueHardwareStatsSlice = createSlice({
  name: 'venueHardwareStats',
  initialState: getInitialReducerStateForId<VenuesHardwareStats>(),
  reducers: {
    getVenueHardwareStatsSuccess: (
      state,
      action: PayloadAction<VenueHardwareStatsResponse>
    ) => {
      state.status = FetchingStatus.Success

      if (action.payload.venueId) {
        state.lastRefreshTimes[action.payload.venueId] = new Date()
        state.data[action.payload.venueId] = action.payload.hardwareStats
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getVenueHardwareStatsRequest: (state, venueId: PayloadAction<string>) => {
      state.status = FetchingStatus.Request
    },

    getVenueHardwareStatsFailure: baseFetchFailure,
  },
})

export const venueNaoConfigSlice = createSlice({
  name: 'venueNaoConfig',
  initialState: getInitialReducerStateForId<VenuesNAOConfig>(),
  reducers: {
    getVenueNaoConfigSuccess: (
      state,
      action: PayloadAction<VenueNAOConfigResponse>
    ) => {
      state.status = FetchingStatus.Success

      if (action.payload.venueId) {
        state.lastRefreshTimes[action.payload.venueId] = new Date()
        state.data[action.payload.venueId] = action.payload.naoConfig
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getVenueNaoConfigRequest: (state, venueId: PayloadAction<string>) => {
      state.status = FetchingStatus.Request
    },
    getVenueNaoConfigFailure: baseFetchFailure,
  },
})

export const venueUsersSlice = createSlice({
  name: 'venueUsers',
  initialState: getInitialReducerStateForId<VenueUsers>(),
  reducers: {
    getVenueUsersSuccess: (
      state,
      action: PayloadAction<VenueUsersResponse>
    ) => {
      state.status = FetchingStatus.Success

      if (action.payload.venueId) {
        state.lastRefreshTimes[action.payload.venueId] = new Date()
        state.data[action.payload.venueId] = action.payload.users
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getVenueUsersRequest: (state, venueId: PayloadAction<string>) => {
      state.status = FetchingStatus.Request
    },
    getVenueUsersFailure: baseFetchFailure,
  },
})

export const { getVenuesSuccess, getVenuesRequest, getVenuesFailure } =
  venuesSlice.actions

export const { getVenueSuccess, getVenueRequest, getVenueFailure } =
  venueSlice.actions

export const {
  getVenueTrackablesSuccess,
  getVenueTrackablesRequest,
  getVenueTrackablesFailure,
} = venueTrackablesSlice.actions

export const {
  getVenueSensorsSuccess,
  getVenueSensorsRequest,
  getVenueSensorsFailure,
} = venueSensorsSlice.actions

export const {
  getVenueSensorsOfflineOnlineStatusSuccess,
  getVenueSensorsOfflineOnlineStatusRequest,
  getVenueSensorsOfflineOnlineStatusFailure,
} = venueSensorsOfflineOnlineStatusSlice.actions

export const {
  getVenueHardwareStatsFailure,
  getVenueHardwareStatsRequest,
  getVenueHardwareStatsSuccess,
} = venueHardwareStatsSlice.actions

export const {
  getVenueNaoConfigFailure,
  getVenueNaoConfigRequest,
  getVenueNaoConfigSuccess,
} = venueNaoConfigSlice.actions

export const {
  getVenueUsersSuccess,
  getVenueUsersRequest,
  getVenueUsersFailure,
} = venueUsersSlice.actions
