import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined'
import LocationCityOutlined from '@material-ui/icons/LocationCityOutlined'
import { PageGroup, PageRoute } from '@midmarkrtls/common/interfaces/modules'
import { PermissionsEnum } from '@midmarkrtls/common/models'
import { getAssociatedRoute } from 'helpers/routing'
import pageRoutes, { RouteKeys } from 'routes'

export const authAllowedPageGroups: PageGroup[] = [
  {
    key: 'customers',
    name: 'Customers',
    link: 'customers',
    icon: <BusinessOutlinedIcon />,
    pages: [],
    permissions: [PermissionsEnum.CustomerRead],
    get associatedRoute(): PageRoute | undefined {
      return getAssociatedRoute(RouteKeys.Customers, pageRoutes)
    },
  },
  {
    key: 'users',
    name: 'Users',
    link: 'users',
    icon: <BusinessOutlinedIcon />,
    pages: [],
    permissions: [PermissionsEnum.UsersRead],
    get associatedRoute(): PageRoute | undefined {
      return getAssociatedRoute(RouteKeys.Users, pageRoutes)
    },
  },
  {
    key: 'venues',
    name: 'Venues',
    link: 'venues',
    icon: <LocationCityOutlined />,
    permissions: [],
    pages: [],
    get associatedRoute(): PageRoute | undefined {
      return getAssociatedRoute(RouteKeys.Venues, pageRoutes)
    },
  },
]
