import { CustomersApi, VenuesApi } from 'middleware'
import { UsersApi } from 'middleware/users'
import { RootState } from 'reducers'
import { AnyAction } from 'redux'
import { createLogger } from 'redux-logger'
import { createEpicMiddleware, EpicMiddleware } from 'redux-observable'

export function setupMiddleware(): EpicMiddleware<
  AnyAction,
  AnyAction,
  RootState
> {
  return createEpicMiddleware<AnyAction, AnyAction, RootState>({
    dependencies: {
      customersApi: new CustomersApi(),
      venuesApi: new VenuesApi(),
      usersApi: new UsersApi(),
    },
  })
}

// eslint-disable-next-line
export function setupLogger() {
  return createLogger({
    collapsed: true,
    predicate: () => process.env.REACT_APP_ENABLE_REDUX_LOGGER === 'true',
  })
}
