import { FetchingState } from '@midmarkrtls/common/interfaces/fetchingState'

export interface FetchingStateForId<T> extends FetchingState<T> {
  lastRefreshTimes: Record<string, Date>
}
export type ReadonlyState<T> = Readonly<FetchingState<T>>
export type ReadonlyStateForId<T> = Readonly<FetchingStateForId<T>>

export function getInitialReducerState<T>(): ReadonlyState<T> {
  const initalState: ReadonlyState<T> = {
    data: {} as T,
    status: undefined,
    lastRefreshTime: undefined,
  }

  return initalState
}

export function getInitialReducerStateForId<T>(): ReadonlyStateForId<T> {
  const initalState: ReadonlyStateForId<T> = {
    data: {} as T,
    status: undefined,
    lastRefreshTime: undefined,
    lastRefreshTimes: {},
  }

  return initalState
}
