import { FetchingStatus } from '@midmarkrtls/common/models'
import { mergeRecords } from '@midmarkrtls/common/utils'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  getInitialReducerState,
  getInitialReducerStateForId,
} from 'models/baseModels'
import { Customer, Customers, CustomerVenues } from 'models/customers'
import { Venue } from 'models/venues'
import { baseFetchFailure, baseFetchRequest } from './baseReducers'

export const customerSlice = createSlice({
  name: 'customers',
  initialState: getInitialReducerState<Customers>(),
  reducers: {
    getCustomersSuccess: (state, action: PayloadAction<Customer[]>) => {
      state.status = FetchingStatus.Success
      state.lastRefreshTime = new Date()
      state.data = mergeRecords<Customers, Customer, 'rtlsTenantKey'>(
        state.data,
        action.payload,
        'rtlsTenantKey'
      )
    },
    getCustomersRequest: baseFetchRequest,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCustomersFailure: baseFetchFailure,
  },
})

export interface CustomerVenuesResponse {
  venues: Venue[]
  customerId: number
}

export const customerVenuesSlice = createSlice({
  name: 'customerVenues',
  initialState: getInitialReducerStateForId<CustomerVenues>(),
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCustomerVenuesSuccess: (
      state,
      action: PayloadAction<CustomerVenuesResponse>
    ) => {
      state.status = FetchingStatus.Success
      state.lastRefreshTimes[action.payload.customerId.toString()] = new Date()

      state.data[action.payload.customerId] = action.payload.venues
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCustomerVenuesRequest: (state, customerId: PayloadAction<number>) => {
      state.status = FetchingStatus.Request
    },
    getCustomerVenuesFailure: baseFetchFailure,
  },
})

export const { getCustomersSuccess, getCustomersRequest, getCustomersFailure } =
  customerSlice.actions

export const {
  getCustomerVenuesSuccess,
  getCustomerVenuesRequest,
  getCustomerVenuesFailure,
} = customerVenuesSlice.actions
