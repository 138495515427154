import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type StatusState = Readonly<string[]>

const initialState: StatusState = []

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<string>) => {
      state = [...state, action.payload]
    },
    clearStatus: (state) => {
      state.slice(1)
    },
  },
})

export const { setStatus, clearStatus } = statusSlice.actions
