import { FetchingState } from '@midmarkrtls/common/interfaces/fetchingState'
import { FetchingStatus } from '@midmarkrtls/common/models'
import { HttpErrorResponse } from '@midmarkrtls/common/services/http'
import { PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'

export function baseFetchRequest(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: WritableDraft<Readonly<FetchingState<any>>>
): void {
  state.status = FetchingStatus.Request
}

export function baseFetchFailure(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: WritableDraft<Readonly<FetchingState<any>>>,
  action: PayloadAction<HttpErrorResponse>
): void {
  state.status = FetchingStatus.Failure
  state.error = action.payload
}
