import { DownForMaintenance } from '@midmarkrtls/common/components/Common/DownForMaintenance'
import { PageRoute } from '@midmarkrtls/common/interfaces/modules'
import { PageNotFound } from '@midmarkrtls/common/pages/Errors'
import React from 'react'

export enum RouteKeys {
  Venues = 'Venues',
  Customers = 'Customers',
  CustomerDetails = 'CustomerDetails',
  VenueDetails = 'VenueDetails',
  SensorAnalysis = 'SensorAnalysis',
  DownForMaintenance = 'DownForMaintenance',
  ErrorPage = 'ErrorPage',
  Dashboard = 'Dashboard',
  Users = 'Users',
  UserDetails = 'UserDetails',
  LocationAlgorithmAnalysis = 'LocationAlgorithmAnalysis',
  TrackablePath = 'TrackablePath',
}

const pageRoutes: PageRoute[] = [
  {
    key: RouteKeys.DownForMaintenance,
    path: '/downformaintenance',
    pageComponent: DownForMaintenance,
    exact: true,
    permissions: [],
    subroutes: [],
  },
  {
    key: RouteKeys.Customers,
    path: '/customers',
    pageComponent: React.lazy(() => import('./containers/CustomerListPage')),
    exact: true,
    permissions: [],
    subroutes: ['/details'],
  },
  {
    key: RouteKeys.Venues,
    path: '/venues',
    pageComponent: React.lazy(() => import('./containers/VenueListPage')),
    exact: true,
    permissions: [],
    subroutes: ['/:venueId', '/:venueId/sensorAnalysis'],
  },
  {
    key: RouteKeys.Users,
    path: '/users',
    pageComponent: React.lazy(() => import('./containers/UserListPage')),
    exact: true,
    permissions: [],
    subroutes: ['/details'],
  },
  {
    key: RouteKeys.UserDetails,
    path: '/users/details',
    pageComponent: React.lazy(() => import('./containers/UserDetailsPage')),
    exact: true,
    permissions: [],
    subroutes: [],
  },
  {
    key: RouteKeys.ErrorPage,
    path: '/errors',
    pageComponent: PageNotFound,
    exact: true,
    permissions: [],
    subroutes: [],
  },
  {
    key: RouteKeys.CustomerDetails,
    path: '/customers/details',
    pageComponent: React.lazy(() => import('./containers/CustomerDetailPage')),
    exact: true,
    permissions: [],
    subroutes: [],
  },
  {
    key: RouteKeys.VenueDetails,
    path: '/venues/:venueId',
    pageComponent: React.lazy(() => import('./containers/VenueDetailPage')),
    exact: true,
    permissions: [],
    subroutes: ['/sensorAnalysis', '/sensorAnalysis/new'],
  },
  {
    key: RouteKeys.SensorAnalysis,
    path: '/venues/:venueId/sensorAnalysis',
    pageComponent: React.lazy(() => import('./containers/SensorAnalysisPage')),
    exact: true,
    permissions: [],
    subroutes: [],
  },
  {
    key: RouteKeys.Dashboard,
    path: '/',
    redirectTo: '/customers',
    pageComponent: React.lazy(() => import('./containers/CustomerListPage')),
    exact: true,
    permissions: [],
    subroutes: [],
  },
  {
    key: RouteKeys.LocationAlgorithmAnalysis,
    path: '/venues/:venueId/analysis/locationAlgorithm',
    pageComponent: React.lazy(
      () => import('./containers/LocationAlgorithmAnalysisPage')
    ),
    exact: true,
    permissions: [],
    subroutes: [],
  },
  {
    key: RouteKeys.TrackablePath,
    path: '/venues/:venueId/analysis/trackablePath',
    pageComponent: React.lazy(() => import('./containers/TrackablePathPage')),
    exact: true,
    permissions: [],
    subroutes: [],
  },
]

export default pageRoutes
