import { AnyAction } from '@reduxjs/toolkit'
import { UsersApi } from 'middleware/users'
import { ReadonlyState } from 'models/baseModels'
import { Users } from 'models/users'
import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import {
  userDetailsCollectionSlice,
  UserDetailsResponse,
  userSlice,
} from '../reducers/users'

type UsersEpic = Epic<
  AnyAction,
  AnyAction,
  ReadonlyState<Users>,
  { usersApi: UsersApi }
>

export const getAllUsersEpic: UsersEpic = (action$, state, { usersApi }) => {
  const actions = userSlice.actions
  return action$.pipe(
    filter(actions.getUsersRequest.match),
    mergeMap(() => {
      return from(usersApi.getAllUsers()).pipe(
        map((users) => {
          return actions.getUsersSuccess(users)
        }),
        catchError((error) => {
          console.log('Error retrieving customers', error)
          return of(actions.getUsersFailure(error))
        })
      )
    })
  )
}

export const getUserDetailsEpic: UsersEpic = (action$, state, { usersApi }) => {
  const actions = userDetailsCollectionSlice.actions
  return action$.pipe(
    filter(actions.getUserDetailsRequest.match),
    mergeMap((action) => {
      return from(usersApi.getUserDetails(action.payload)).pipe(
        map(($userDetails) => {
          const payload: UserDetailsResponse = {
            userDetails: $userDetails,
            objectId: action.payload,
          }
          return actions.getUserDetailsSuccess(payload)
        }),
        catchError((error) => {
          console.log('Error retrieving user details', error)
          return of(actions.getUserDetailsFailure(error))
        })
      )
    })
  )
}
