import { RootState } from 'reducers'
import { AnyAction } from 'redux'
import { combineEpics, Epic } from 'redux-observable'
import * as CustomersEpic from './customers'
import * as UsersEpic from './users'
import * as VenuesEpic from './venues'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RootEpic = Epic<AnyAction, AnyAction, RootState, any>

const epics: Epic[] = [
  ...Object.values(CustomersEpic),
  ...Object.values(VenuesEpic),
  ...Object.values(UsersEpic),
]

export default combineEpics(...epics)
