import CurrentLocationsReducer from '@midmarkrtls/common/reducers/index'
import { createAction } from '@reduxjs/toolkit'
import { AnyAction, combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { StateType } from 'typesafe-actions'
import { authSlice } from './auth'
import { customerSlice, customerVenuesSlice } from './customers'
import { navigationSlice } from './navigation'
import { statusSlice } from './status'
import { userDetailsCollectionSlice, userSlice } from './users'
import {
  venueHardwareStatsSlice,
  venueNaoConfigSlice,
  venueSensorsOfflineOnlineStatusSlice,
  venueSensorsSlice,
  venueSlice,
  venuesSlice,
  venueTrackablesSlice,
  venueUsersSlice,
} from './venues'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'navigation', 'currentLocation'],
}

export const resetStoreAction = createAction('resetStore')

const appReducer = combineReducers({
  auth: authSlice.reducer,
  status: statusSlice.reducer,
  customers: customerSlice.reducer,
  customerVenues: customerVenuesSlice.reducer,
  currentLocation: CurrentLocationsReducer.currentLocation,
  users: userSlice.reducer,
  userDetails: userDetailsCollectionSlice.reducer,
  venueUsers: venueUsersSlice.reducer,
  venues: venuesSlice.reducer,
  venueMap: venueSlice.reducer,
  navigation: navigationSlice.reducer,
  venueNaoConfig: venueNaoConfigSlice.reducer,
  venueHardwareStats: venueHardwareStatsSlice.reducer,
  venueTrackablesMap: venueTrackablesSlice.reducer,
  venueSensorsMap: venueSensorsSlice.reducer,
  venueSensorsOfflineOnlineStatus: venueSensorsOfflineOnlineStatusSlice.reducer,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: AnyAction) => {
  if (action === resetStoreAction) {
    storage.removeItem('persist:root')
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export type RootState = StateType<typeof rootReducer>

export default persistReducer(persistConfig, rootReducer)
