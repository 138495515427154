import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import { AuthConfig } from '../models'
import { customerIdClaim, storageStatePath } from './auth.config'

class AuthenticationManager {
  private application: IPublicClientApplication | null = null
  private config: AuthConfig | null = null
  private storage: Storage = localStorage
  private static instance: AuthenticationManager

  constructor() {
    if (AuthenticationManager.instance) {
      throw new Error('Only one Authentication Manager can exist at a time.')
    }
  }

  public static getInstance(): AuthenticationManager {
    if (!AuthenticationManager.instance) {
      AuthenticationManager.instance = new AuthenticationManager()
    }

    return AuthenticationManager.instance
  }

  public get authConfig(): AuthConfig {
    if (this.config === null) {
      throw new Error('No authentication config specified')
    }

    return this.config
  }

  public setStorage(storage: Storage): void {
    this.storage = storage
  }

  public get clientApplication(): IPublicClientApplication {
    if (this.application === null) {
      throw new Error('No authentication instance configured')
    }

    return this.application
  }

  public get account(): AccountInfo {
    return this.clientApplication?.getAllAccounts()[0]
  }

  public setAuthConfig(config: AuthConfig): void {
    this.config = config
  }

  public isLoggedIn(): boolean {
    return !!this.account
  }

  public updateClientApplication(
    instanceChange: IPublicClientApplication
  ): void {
    this.application = instanceChange
  }

  public clearStorage(): void {
    this.storage.removeItem(storageStatePath)
  }

  public get customerId(): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const id = (this.account.idTokenClaims as any)?.[customerIdClaim]

    if (!id) {
      throw new Error('Unable to find customer claim')
    }

    return id
  }

  public logout(): void {
    localStorage.clear()
    sessionStorage.clear()
    this.clientApplication.logoutRedirect()
  }
}

export const Authentication = AuthenticationManager.getInstance()

export default AuthenticationManager
