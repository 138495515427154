import {
  Avatar,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  MenuItem,
} from '@material-ui/core/'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { useAuth } from 'hooks/useAuth'
import { MouseEvent, useCallback, useState } from 'react'
import { colors } from 'styles/MidmarkTheme'
import { Authentication } from '../../authentication/AuthenticationManager'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #8d8d8d',
    height: 82,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

const useStyles = makeStyles(() =>
  createStyles({
    profileIcon: {
      color: colors.background,
    },
    avatar: {
      height: 26,
      width: 26,
      fontSize: 12,
      fontWeight: 'bold',
    },
    menuHeader: {
      width: 200,
      height: 32,
      overflow: 'hidden',
      fontSize: '16px',
      boxSizing: 'border-box',
      fontFamily: 'Ubuntu',
      whiteSpace: 'nowrap',
      paddingLeft: 12,
      paddingTop: '1px',
      paddingBottom: 'unset',
    },
    menuItem: {
      width: 200,
      height: 28,
      paddingLeft: 12,
      marginTop: 4,
      marginBottom: 0,
      paddingTop: 7,
    },
    divider: {
      backgroundColor: '#8d8d8d',
    },
    background1: {
      backgroundColor: '#8D1B60',
    },
    background2: {
      backgroundColor: '#D9597C',
    },
    background3: {
      backgroundColor: '#78BE20',
    },
    background4: {
      backgroundColor: '#00A19B',
    },
    background5: {
      backgroundColor: '#285F68',
    },
    background6: {
      backgroundColor: '#165788',
    },
  })
)

const UserMenu = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [avatarBackground, setAvatarBackground] = useState<string>()
  const { user } = useAuth()

  const handleMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(!menuOpen)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setMenuOpen(false)
  }

  const handleLogout = () => {
    Authentication.logout()
    handleClose()
  }

  const classes = useStyles()

  const formatAvatar = useCallback(
    () =>
      `${user.firstName.slice(0, 1).toUpperCase()}${user.lastName
        .slice(0, 1)
        .toUpperCase()}`,
    [user]
  )

  if (!avatarBackground) {
    const firstLetter = user.firstName.slice(0, 1).toUpperCase()
    switch (firstLetter) {
      case 'A':
      case 'B':
      case 'C':
      case 'D':
      case 'E':
        setAvatarBackground(classes.background1)
        break
      case 'F':
      case 'G':
      case 'H':
      case 'I':
      case 'J':
        setAvatarBackground(classes.background2)
        break
      case 'K':
      case 'L':
      case 'M':
      case 'N':
      case 'O':
        setAvatarBackground(classes.background3)
        break
      case 'P':
      case 'Q':
      case 'R':
      case 'S':
        setAvatarBackground(classes.background4)
        break
      case 'T':
      case 'U':
      case 'V':
      case 'W':
        setAvatarBackground(classes.background5)
        break
      case 'X':
      case 'Y':
      case 'Z':
        setAvatarBackground(classes.background6)
        break
      default:
        setAvatarBackground(classes.background1)
        break
    }
  }

  return (
    <div>
      <IconButton onClick={handleMenu} classes={{ root: classes.profileIcon }}>
        <Avatar className={`${classes.avatar} ${avatarBackground}`}>
          {formatAvatar()}
        </Avatar>
        {menuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </IconButton>
      <StyledMenu
        id='menu-appbar'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{
          top: 25,
        }}
      >
        <div
          className={classes.menuHeader}
        >{`${user.firstName} ${user.lastName}`}</div>
        <Divider className={classes.divider} />
        <MenuItem className={classes.menuItem} onClick={handleLogout}>
          Logout
        </MenuItem>
      </StyledMenu>
    </div>
  )
}

export default UserMenu
