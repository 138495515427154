import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Navigation } from 'models'
import { getInitialReducerState } from 'models/baseModels'

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: getInitialReducerState<Navigation>(),
  reducers: {
    setCurrentVenueId: (state, venueId: PayloadAction<string>) => {
      state.data.currentVenueId = venueId.payload
    },
    setCurrentCustomerId: (state, customerId: PayloadAction<string>) => {
      state.data.currentCustomerId = customerId.payload
    },
    setCurrentUserObjectId: (state, objectId: PayloadAction<string>) => {
      state.data.currentUserObjectId = objectId.payload
    },
  },
})

export const {
  setCurrentVenueId,
  setCurrentCustomerId,
  setCurrentUserObjectId,
} = navigationSlice.actions
