import { configureStore } from '@reduxjs/toolkit'
import rootEpic from 'epics'
import rootReducer from 'reducers'
import { Persistor, persistStore } from 'redux-persist'
import { setupLogger, setupMiddleware } from './setupMiddleware'

const setUpStore = (preloadedState?: Record<string, unknown>) => {
  const middlewareEpics = setupMiddleware()

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      //serializableCheck errors are thrown for any class instance (such as the lastRefreshTime Date that is used in the common models)
      getDefaultMiddleware({ thunk: false, serializableCheck: false })
        .concat(middlewareEpics)
        .concat(setupLogger()),
    devTools: process.env.NODE_ENV === 'production',
    preloadedState: preloadedState,
  })

  middlewareEpics.run(rootEpic)
  return store
}

const Store = setUpStore()

let persistor = persistStore(Store)

export function getPersistor(store?: typeof Store): Persistor {
  if (!persistor) {
    persistor = persistStore(store ?? Store)
  }
  return persistor
}

export default Store
