import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthStore } from 'models'
import { B2CUser } from 'models/b2cUser'

const initialState: AuthStore = {
  authenticated: false,
  user: {
    customerId: '',
    firstName: '',
    id: '',
    lastName: '',
    name: '',
  },
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setAuthenticatedUser: (state, user: PayloadAction<B2CUser>) => {
      state.authenticated = !!user.payload?.id?.length
      state.user = user.payload
    },
  },
})

export const { setAuthenticatedUser } = authSlice.actions
