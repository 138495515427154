/* ******************************************** */
/*  Global overrides of Material UI Components  */
/* ******************************************** */
import { createTheme } from '@material-ui/core/styles'

export default createTheme({
  palette: {
    primary: {
      main: '#236092',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
      contrastText: '#000',
    },
    info: {
      main: '#fff',
      contrastText: '#000',
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: '$labelcolor',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Ubuntu, Verdana, Arial, sans-serif',
  },
})

export const colors = {
  primary: '#236092',
  secondaryBlue: '#58c9e7',
  secondaryBlueHalf: '#58c9e780',
  indigo600: '#2a9ad1',
  magenta: '#e54360',
  magentaHalf: '#e5436099',
  error: '#ce0e2d',
  green: '#78be20',
  hoverBackground: '#42b4e399', // 42b4e3 @ 60%opacity
  secondaryGreen: '#9acf58',
  darkSurface: '#1c1b1b', // Fill Color
  background: '#6f6e6e',
  darkTeal: '#204152',
  darkMagenta: '#af1834',
  darkGrey: '#666666',
  darkGrey2: '#4A545D',
  darkGrey3: '#373939',
  midGrey: '#9e9e9e',

  black: '#000000',
  grey800: '#181b1f',
  grey700: '#212428',
  grey600: '#303336',
  grey500: '#3d3d3d',
  grey400: '#46494c',
  grey300: '#a3a3a3',
  grey200: '#a2a4a5',
  offWhite100: '#e7e7e7',
  offWhite50: '#efefef',
  offWhite25: '#f8f8f8',
  offWhite: '#d8d8d8',
  white: '#ffffff',

  primaryBlue0: '#175788',
  primaryBlue100: '#093762',
  secondaryBlue0: '#c1cdd8',
  secondaryBlue100: '#5dc0e7',
  secondaryBlue200: '#30a8df',

  brandBlue: '#63cbe8',
  brandDarkBlue: '#00548a',
  brandRed: '#e54360',
  dataBlue: '#236192',
  dataMagenta: '#8a1b61',
  dataTurqoise: '#00a499',

  notificationsBlue0: '#dbf4ff',
  notificationsBlue100: '#2da1d6',
  notificationsBlue200: '#1a293c',
  notificationGreen0: '#e4f2d2',
  notificationsGreen100: '#78be21',
  notificationsGreen200: '#21421b',
  notificationRed0: '#ffe5e7',
  notificationsRed100: '#ff0b1e',
  notificationsRed200: '#4f2e31',
  notificationsYellow0: '#fff5d9',
  notificationsYeloow100: '#ffba00',
  notificationsYellow200: '#4b460f',

  safetyDangerRed: '#bd2024',
  safetyWarningOrange: '#ff7900',
  safetyWarningOrangeTint: '#ffe4cc',
  safetyCautionYellow: '#ffe100',
  safetyNoticeBlue: '#004488',
  safetyNoticeBlueTint: '#e6ecf3',

  fillColor: '#165788',
  transparent: 'rgba(0, 0, 0, 0)',
  assetCurrent: '#165788',
  asseRecent: '#2a9ad1',
  assetTimedOut: '#333333',
  staffCurrent: '#006561',
  staffRecent: '#33b4af',
  staffTimeOut: '#313131',
  staff: '#006561',
  duress: '#ce0e2d',
  duressBubble: '#f6cdd3',
  duressConfidenceBubble: 'ce0e2d',
  duressTableHighlight: '#f6cdd3',
  duressTableSelect: '#D6CCD7',
  unitColor1: '#8D1B60',
  unitColor2: '#78BE20',
  unitColor3: '#D9597C',
  unitColor4: '#285F68',
  avatarBackground1: '#8D1B60',
  avatarBackground2: '#D9597C',
  avatarBackground3: '#78BE20',
  avatarBackground4: '#00A19B',
  avatarBackground5: '#285F68',
  avatarBackground6: '#165788',
  outlineColor: '#54C8E8',
  warning: '#ff8300',

  // Sensor Status Colors
  unselectedSensor: '#1A293C',
  selectedSensor: '#E54360',
  selectedTrackable: '#00A49980',
  unconfiguredSensor: '#65CFE9',
  offlineSensor: '#E44D69',
  onlineSensor: '#9ACF58',
  unplacedSensor: '#FFEA00',
  sensorHasReportedTrackable: '#2DA1D6',
  sensorHasNotReportedTrackable: '#1A293C',
  sensorHasNoReports: '#E44D69',

  // DataTable
  dataTableHoverBackground: '#bedde6',
  dataTableSelected: '#2DA1D680',

  // Staff Assist Colors
  assistTableHighlight: '#F2EAE1',
  assistTableSelect: '#C3E0E3',
  assistDetailDrawerColor: '#FFE6CC',

  // defaults from MUI that we are overriding, bringing them back
  disabledButtonBackground: 'rgba(0, 0, 0, 0.26)',
  disabledButtonText: 'rgba(0, 0, 0, 0.26)',
}

export const fontSizes = {
  largeFont: '1.5rem',
  mediumFont: '1rem',
  smallFont: '.75rem',
}

export const tableColors = {
  background: '#f9f9f9',
  borderColor: '#c4c4c4',
  textColor: '#333333',
  headerColor: '#666666',
}
