import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import SideNavLink from '@midmarkrtls/common/components/Common/SideNav/SideNavLink'
import { Page } from '@midmarkrtls/common/interfaces/modules'
import clsx from 'clsx'
import React, { ReactElement, useMemo } from 'react'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  classes: any
  icon: ReactElement
  isExpanded: boolean
  isOpen: boolean
  name: string
  pages: Page[]
  pageGroupKey: string
  selectedPage: string | undefined
  handlePageSelect: (groupKey: string) => void
  handlePageGroupClick: (groupKey: string, expanded: boolean) => void
}

const PageGroup: React.FC<Props> = (props: Props) => {
  const {
    name,
    icon,
    pages,
    classes,
    isOpen,
    selectedPage,
    isExpanded,
    pageGroupKey,
    handlePageSelect,
    handlePageGroupClick,
  } = props

  const carretClass = useMemo(() => {
    if (selectedPage) {
      return null
    }
    return isExpanded ? classes.caretExpanded : classes.caretCollapsed
  }, [selectedPage, isExpanded, classes.caretCollapsed, classes.caretExpanded])

  return (
    <List disablePadding className={classes.pageGroup}>
      {/* Page Group Row */}
      <Tooltip title={!isOpen ? name : ''} placement='right'>
        <ListItem
          button
          component='li'
          onClick={() => {
            if (!selectedPage) {
              handlePageGroupClick(pageGroupKey, !isExpanded)
            }
          }}
          disableGutters
          classes={{
            root: clsx(
              selectedPage ? classes.subSelectedRow : '',
              classes.row,
              carretClass
            ),
          }}
        >
          <ListItemIcon classes={{ root: classes.iconCell }}>
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={name}
            primaryTypographyProps={{ variant: 'button' }}
            classes={{ primary: classes.pageListItemText }}
          />
        </ListItem>
      </Tooltip>
      {/* Page Rows */}
      <Collapse in={isExpanded} component='li'>
        <ul style={{ padding: 0 }}>
          {pages.map((page) => {
            return (
              <li key={page.key}>
                <SideNavLink
                  to={`/${page.link}`}
                  name={page.name}
                  icon={page.icon}
                  isOpen={isOpen}
                  classes={classes}
                  hasSelectedSibling={!!selectedPage}
                  isSelected={page.key === selectedPage}
                  pageKey={page.key}
                  pageGroupKey={pageGroupKey}
                  handlePageSelect={handlePageSelect}
                />
              </li>
            )
          })}
        </ul>
      </Collapse>
    </List>
  )
}

export default PageGroup
