import { AuthStore } from 'models'
import { useSelector } from 'react-redux'

const useAuth = (): Readonly<AuthStore> => {
  const authState = useSelector(
    ({ auth }: { auth: Readonly<AuthStore> }) => auth
  )

  return {
    ...authState,
  }
}

export { useAuth }
