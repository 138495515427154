import { LogLevel, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { RightDrawerProvider } from '@midmarkrtls/common/components/Common/RightDrawer/useRightDrawer'
import { SecondaryHeaderProvider } from '@midmarkrtls/common/components/Common/SecondaryHeader/useSecondaryHeader'
import withClearCache from '@midmarkrtls/common/components/withClearCache'
import { Authentication } from 'authentication/AuthenticationManager'
import { ProtectedContent } from 'components/Authentication'
import { WindowContextProvider } from 'helpers/windowContext'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import routes from 'routes'
import MidmarkTheme from 'styles/MidmarkTheme'
import packageJSON from '../../package.json'
import AuthConfig from '../msalConfig'
import store, { getPersistor } from '../store/configureStore'
import RootLayout from './RootLayout'

//TODO - telemetry support

const logCallback = function (
  logLevel: LogLevel,
  message: string,
  containsPii: boolean
) {
  console.log(`${logLevel}: ${message}`)
}

const c = {
  ...AuthConfig.msalConfig,
  system: {
    loggerOptions: {
      loggerCallback: logCallback,
      logLevel: LogLevel.Warning,
      piiLoggingEnabled: false,
    },
  },
}

console.log(c)

const msalInstance = new PublicClientApplication(c)
Authentication.setAuthConfig(AuthConfig)

const Root = () => {
  return (
    <>
      <WindowContextProvider>
        <CssBaseline />
        <MuiThemeProvider theme={MidmarkTheme}>
          <Router>
            <Provider store={store}>
              <PersistGate loading={null} persistor={getPersistor()}>
                <MsalProvider instance={msalInstance}>
                  <SecondaryHeaderProvider>
                    <RightDrawerProvider>
                      <ProtectedContent
                        loginRequest={AuthConfig.loginRequest}
                        routes={routes}
                        RootLayout={RootLayout}
                      />
                    </RightDrawerProvider>
                  </SecondaryHeaderProvider>
                </MsalProvider>
              </PersistGate>
            </Provider>
          </Router>
        </MuiThemeProvider>
      </WindowContextProvider>
    </>
  )
}

export default withClearCache(Root, packageJSON)
