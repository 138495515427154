import { Venue } from '@midmarkrtls/common/models'
import { Customer } from '../models/customers'
import { BaseService } from './baseService'

export class CustomersApi extends BaseService {
  constructor() {
    super({ entityType: 'customers', version: 1 })
  }
  route = 'Customers'

  async getAllCustomers(): Promise<Customer[]> {
    return await this.get<Customer[]>(this.route)
  }

  async getAllVenuesForCustomer(customerId: number): Promise<Venue[]> {
    return await this.get<Venue[]>(`customers/${customerId}/venues`)
  }
}
