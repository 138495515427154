import { Grid, Typography } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import midmarkLogo from 'assets/Midmark-logo-48.png'
import React from 'react'
import UserMenu from './UserMenu'

const headerMaxHeight = 48

interface Props {
  headerItems?: React.ReactNode[]
  handleLogoClick: () => void
}

const Header = (props: Props): JSX.Element => {
  const { headerItems = [], handleLogoClick } = props

  return (
    <>
      <AppBar
        color='secondary'
        style={{ maxHeight: headerMaxHeight }}
        position='relative'
      >
        <Toolbar
          variant='dense'
          style={{ paddingLeft: '80px', paddingRight: '8px' }}
        >
          <a
            style={{ cursor: 'pointer', marginRight: '8px' }}
            onClick={handleLogoClick}
          >
            <img src={midmarkLogo} width='72' alt='logo' />
          </a>
          <div style={{ marginLeft: '40px' }}></div>
          <div style={{ flexGrow: 1 }} />
          <Grid
            container
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing={2}
          >
            <Typography
              variant='body2'
              component={'span'}
              style={{
                fontWeight: 'normal',
              }}
            >
              <h4>BTS Management Portal</h4>
            </Typography>

            {headerItems.map((headerItem, i) => (
              <div key={`headerItem-${i}`}>{headerItem}</div>
            ))}
            <Grid item>
              <UserMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header
