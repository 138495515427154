import { CalculatedTrackableLocation, TrackableLocation } from 'models'
import { BaseService } from './baseService'

export class TrackableLocationsApi extends BaseService {
  constructor() {
    super({
      entityType: 'trackableLocations',
      version: 1,
    })
  }

  async retrieveTrackableLocations(
    venueId: string,
    trackableMacAddress: string,
    startDate: string,
    endDate: string
  ): Promise<TrackableLocation[]> {
    const result = await this.get<TrackableLocation[]>(
      `venues/${venueId}/trackables/${trackableMacAddress}/locations?startDate=${startDate}&endDate=${endDate}`
    )

    result.forEach((x) => (x.timestamp = new Date(x.timestamp)))

    return result
  }

  async retrieveCalculatedTrackableLocations(
    venueId: string,
    trackableMacAddress: string,
    startDate: string,
    endDate: string,
    aggSeconds: number,
    reportSeconds: number,
    variant: string
  ): Promise<CalculatedTrackableLocation[]> {
    const result = await this.get<CalculatedTrackableLocation[]>(
      `venues/${venueId}/trackables/${trackableMacAddress}/calculatedLocations?startDate=${startDate}&endDate=${endDate}&aggregationSeconds=${aggSeconds}&reportingSeconds=${reportSeconds}&variant=${variant}`
    )

    result.forEach((x) => (x.timestamp = new Date(x.timestamp)))

    return result
  }
}
