import { FetchingStatus } from '@midmarkrtls/common/models'
import { mergeRecords } from '@midmarkrtls/common/utils'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  getInitialReducerState,
  getInitialReducerStateForId,
} from 'models/baseModels'
import { User, UserDetails, UserDetailsKeyValuePair, Users } from 'models/users'
import { baseFetchFailure, baseFetchRequest } from './baseReducers'

export const userSlice = createSlice({
  name: 'users',
  initialState: getInitialReducerState<Users>(),
  reducers: {
    getUsersSuccess: (state, action: PayloadAction<User[]>) => {
      state.status = FetchingStatus.Success
      state.lastRefreshTime = new Date()
      state.data = mergeRecords<Users, User, 'objectId'>(
        state.data,
        action.payload,
        'objectId'
      )
    },
    getUsersRequest: baseFetchRequest,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getUsersFailure: baseFetchFailure,
  },
})

export interface UserDetailsResponse {
  userDetails: UserDetails
  objectId: string
}

export const userDetailsCollectionSlice = createSlice({
  name: 'userDetails',
  initialState: getInitialReducerStateForId<UserDetailsKeyValuePair>(),
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getUserDetailsSuccess: (
      state,
      action: PayloadAction<UserDetailsResponse>
    ) => {
      state.status = FetchingStatus.Success
      state.lastRefreshTimes[action.payload.objectId] = new Date()
      state.data[action.payload.objectId] = action.payload.userDetails
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getUserDetailsRequest: (state, objectId: PayloadAction<string>) => {
      state.status = FetchingStatus.Request
    },
    getUserDetailsFailure: baseFetchFailure,
  },
})

export const { getUsersSuccess, getUsersRequest, getUsersFailure } =
  userSlice.actions

export const {
  getUserDetailsSuccess,
  getUserDetailsRequest,
  getUserDetailsFailure,
} = userDetailsCollectionSlice.actions
