import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
} from '@azure/msal-browser'
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from '@azure/msal-react'
import { LoadingPageFull } from '@midmarkrtls/common/pages/LoadingPageFull'
import { Authentication } from 'authentication/AuthenticationManager'
import { AuthStore } from 'models'
import { B2CUser } from 'models/b2cUser'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAuthenticatedUser } from 'reducers/auth'
import LoadingPage from '../Common/LoadingPage'
import Routes from '../Routes/Routes'
import './ProtectedContent.css'

const parseAuthenticationResult = (
  response: AuthenticationResult
): B2CUser | null => {
  if (!response || !response.account) {
    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const account = response.account.idTokenClaims as any

  return {
    id: account.sub || account.idToken.sub,
    name: account.name || account.idToken.name,
    firstName: account.given_name || '',
    lastName: account.family_name || '',
    customerId: account.extension_B2CCustomerId || '',
  }
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loginRequest: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routes: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  RootLayout: React.ComponentType<any>
}

const ProtectedRoutes = ({
  loginRequest,
  routes,
  RootLayout,
}: Props): JSX.Element | null => {
  const { instance, accounts, inProgress } = useMsal()

  // each time useMsal adjusts its instance we set
  // it to a class which is accessible outside of components
  Authentication.updateClientApplication(instance)

  const account = useAccount(accounts[0] || {})
  const authState = useSelector(
    ({ auth }: { auth: Readonly<AuthStore> }) => auth
  )

  const dispatch = useDispatch()

  const handleUserAuthentication = useCallback(
    (response: AuthenticationResult) => {
      const b2cUser = parseAuthenticationResult(response)
      if (!b2cUser) {
        throw 'Could not parse the user from the token'
      }

      dispatch(setAuthenticatedUser(b2cUser))
    },
    [dispatch]
  )

  useEffect(() => {
    if (
      !authState.user.id &&
      account &&
      inProgress === InteractionStatus.None
    ) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then(handleUserAuthentication)
        .catch((err) => {
          Authentication.logout()
          return err
        })
    }
  }, [
    account,
    inProgress,
    instance,
    authState.user,
    handleUserAuthentication,
    loginRequest,
  ])

  return authState.user.id ? (
    <div style={{ animation: 'fadeIn 1s' }}>
      <RootLayout>
        <React.Suspense fallback={<LoadingPage />}>
          <Routes routes={routes} />
        </React.Suspense>
      </RootLayout>
    </div>
  ) : (
    <LoadingPageFull />
  )
}

const ProtectedContent = (props: Props): JSX.Element => {
  const authRequest = {
    ...props.loginRequest,
  }
  const [show, setShow] = React.useState(false)

  useEffect(() => {
    // MsalAuthenticationTemplate uses an internal context to determine whether
    // or not to show the LoadingComponent. Introducing an artificial delay fixes flashing
    const timeout = setTimeout(() => {
      setShow(true)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [show])

  return show ? (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      loadingComponent={LoadingPageFull}
    >
      <ProtectedRoutes {...props} />
    </MsalAuthenticationTemplate>
  ) : (
    <LoadingPageFull />
  )
}

export { ProtectedContent }
