import { Button, Drawer, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { setSideNavIsOpenAction } from '@midmarkrtls/common/actions/sidenav'
import { PageGroup } from '@midmarkrtls/common/interfaces/modules'
import clsx from 'clsx'
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { colors } from '../../../styles/MidmarkTheme'
import SideNavLinks from './SideNavLinks'

const drawerClosedWidth = 56
const drawerOpenedWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginLeft: 4,
      marginRight: 4,
      color: '#58c9e7',
    },
    drawerButton: {
      color: 'white',
      height: drawerClosedWidth + 'px',
      minWidth: drawerClosedWidth + 'px',
      textTransform: 'none',
      borderTop: '1px solid ' + colors.background,
      borderRadius: '0px',
      padding: 0,
    },
    drawerIcon: {
      color: colors.magenta,
      minWidth: drawerClosedWidth + 'px',
      justifyContent: 'center',
    },
    drawerPaper: {
      overflow: 'hidden',
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerOpenedWidth,
      border: 0,
      backgroundColor: colors.darkSurface,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '@media print': {
        display: 'none',
      },
    },
    drawerPaperClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: '56px',
      '@media print': {
        display: 'none',
      },
    },
  })
)

interface Props {
  pageGroups: PageGroup[]
}

const SideNav: React.FC<Props> = (props: Props) => {
  const { pageGroups } = props
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const dispatch = useDispatch()

  return (
    <Drawer
      variant='permanent'
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
      onAnimationEnd={(): unknown => dispatch(setSideNavIsOpenAction(open))}
    >
      <SideNavLinks isOpen={open} pageGroups={pageGroups} />
      <Button
        aria-label='open drawer'
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        classes={{ root: classes.drawerButton }}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          {open ? (
            <Fragment>
              <ChevronLeft classes={{ root: classes.drawerIcon }} />
              <Typography color='inherit'>Collapse</Typography>
            </Fragment>
          ) : (
            <ChevronRight
              classes={{ root: classes.drawerIcon }}
              style={{ marginRight: 'auto' }}
            />
          )}
        </div>
      </Button>
    </Drawer>
  )
}

export default SideNav
