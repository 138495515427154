import { AnyAction } from '@reduxjs/toolkit'
import { CustomersApi } from 'middleware'
import { Customers } from 'models'
import { ReadonlyState } from 'models/baseModels'
import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import {
  customerSlice,
  CustomerVenuesResponse,
  customerVenuesSlice,
} from '../reducers/customers'

type CustomersEpic = Epic<
  AnyAction,
  AnyAction,
  ReadonlyState<Customers>,
  { customersApi: CustomersApi }
>

export const getAllCustomersEpic: CustomersEpic = (
  action$,
  state,
  { customersApi }
) => {
  const actions = customerSlice.actions
  return action$.pipe(
    filter(actions.getCustomersRequest.match),
    mergeMap(() => {
      return from(customersApi.getAllCustomers()).pipe(
        map((customers) => {
          return actions.getCustomersSuccess(customers)
        }),
        catchError((error) => {
          console.log('Error retrieving customers', error)
          return of(actions.getCustomersFailure(error))
        })
      )
    })
  )
}

export const getAllVenuesForCustomerEpic: CustomersEpic = (
  action$,
  state,
  { customersApi }
) => {
  const actions = customerVenuesSlice.actions
  return action$.pipe(
    filter(actions.getCustomerVenuesRequest.match),
    mergeMap((action) => {
      return from(customersApi.getAllVenuesForCustomer(action.payload)).pipe(
        map((customerVenues) => {
          const payload: CustomerVenuesResponse = {
            venues: customerVenues,
            customerId: action.payload,
          }
          return actions.getCustomerVenuesSuccess(payload)
        }),
        catchError((error) => {
          console.log('Error retrieving venues for customer', error)
          return of(actions.getCustomerVenuesFailure(error))
        })
      )
    })
  )
}
