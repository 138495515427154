import { PageRoute } from '@midmarkrtls/common/interfaces/modules'
import { matchPath } from 'react-router-dom'

export const pathMatches = (routePath: string, pathname: string): boolean => {
  const match = matchPath(pathname, {
    path: routePath,
    exact: true,
    strict: false,
  })

  return !!match
}

export const getAssociatedRoute = (
  routeKey: string,
  availableRoutes: PageRoute[]
): PageRoute | undefined => {
  return availableRoutes.find((x) => x.key === routeKey)
}
